import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { postAxios } from '../../axios/Axios';
import request from '../../request/request';
import Config from "../../config/Config"
import Constants from '../constants/Constants';

function Login({ setLogin, changeToast }) {
  const [loginLoading, setLoginLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const submitLoginForm = async (e) => {
    e.preventDefault();
    setLoginLoading(true);

    const email = document.getElementById("form1Example13").value;
    const password = document.getElementById("form1Example23").value;

    if (!email) {
      setEmailError("Email is required");
      setLoginLoading(false);
      return;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      setLoginLoading(false);
      return;
    } else {
      setPasswordError("");
    }

    const data = {
      username: email,
      password: password
    };

    try {
      const res = await postAxios(request.loginForm, data);
      if (res.data.apiresponse.type === "OK") {
        setLogin(false);
        changeToast(Constants.TOAST_SUCCESS, Constants.TOAST_SUCCESS_TITLE, "Login Successful");
        
      } else {
        changeToast(Constants.TOAST_ERROR, Constants.TOAST_ERROR_TITLE, "Incorrect Password");
      }
    } catch (error) {
      console.error('Login Error:', error);
      changeToast(Constants.TOAST_ERROR, Constants.TOAST_ERROR_TITLE, "Login Failed");
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <section className="vh-100">
      <Container fluid className='h-100'>
        <div className="row h-100">
          <div className="col-md-6 d-flex align-items-center justify-content-center d-none d-md-flex">
            <img src={Config.loginLogo} className="img-fluid" alt="Phone" style={{ maxWidth: "75%", height:'50%' }} />
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center" style={{ backgroundColor: "#fef1db" }}>
            <div className="w-75">
              <form onSubmit={submitLoginForm}>
                <p className='h1 text-center mb-6'>Welcome to <span className=''>Babbusiya</span></p>
                <p className='h3 mb-4'>Sign In</p>
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="form1Example13">Email address</label>
                  <input type="text" id="form1Example13" className="form-control form-control-lg" style={{ border: "1px solid lightgrey" }} />
                  <p className="text-danger">{emailError}</p>
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="form1Example23">Password</label>
                  <input type="password" id="form1Example23" className="form-control form-control-lg" style={{ border: "1px solid lightgrey" }} />
                  <p className="text-danger">{passwordError}</p>
                </div>

                <button type="submit" className="btn btn-lg btn-block border-0 text-white" style={{ backgroundColor: "#f6aa1f" }} disabled={loginLoading}>
                  {loginLoading ? "Authenticating..." : "Sign in"}
                </button>

              </form>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Login;
