import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { deleteAxios, getAxios, postAxios } from '../../axios/Axios';
import request from '../../request/request';
import { useLocation, useNavigate } from 'react-router-dom';
import Constants from '../constants/Constants';
import { ConfirmDialog } from 'primereact/confirmdialog';
import AsyncSelect from 'react-select/async';
import { Dialog } from 'primereact/dialog';
import Loader from '../loader/Loader';

import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../utils/helper';

export const UpdateProductCategory = ({ changeToast }) => {
	const [categoryIconPreview, setCategoryIconPreview] = useState(null);
	const [existingBanners, setExistingBanners] = useState([]);
	const [newBanners, setNewBanners] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [selectedParentCategory, setSelectedParentCategory] = useState([]);
	const [loading, setLoading] = useState(false);
	const categoryIconInputRef = useRef(null);
	const bannerInputRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const productCATID = location.state;
	const [data, setData] = useState();
	const [crop, setCrop] = useState({ x: 0, y: 0, width: '100%' });
	const [zoom, setZoom] = useState(1);
	const [completedCrop, setCompletedCrop] = useState(null);
	const [src, setSrc] = useState(null);
	const [blankBanner, setBlankBanner] = useState([]);

	const globalCategoryDetails = [
		{
			label: 'CATEGORY NAME',
			name: data?.category_name,
			ID: data?.product_category_id,
		},

		{
			label: 'PARENT CATEGORY NAME',
			name: data?.parent_category_name,
			ID: data?.primary_parent_category_id,
		},

		{
			label: 'DESCRIPTION',
			name: data?.description,
		},
	];
	const options = [
		{
			value: `${data?.primary_parent_category_id}`,
			label: `${data?.parent_category_name}(${data?.primary_parent_category_id})`,
		},
	];

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const response = await getAxios(`${request.globalCategoryList}`, {
				params: {
					more_details: 1,
					product_category_id: productCATID,
				},
			});
			if (response?.data.apiresponse?.type === 'OK') {
				setData(response?.data?.record[0]);
			}

			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [productCATID]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleCategoryIconChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setCategoryIconPreview(URL.createObjectURL(file));
		} else {
			setCategoryIconPreview(null);
		}
	};

	const handleBannerChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () =>
				setSrc(reader.result?.toString() || '')
			);
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const onCropChange = (newCrop) => {
		if (newCrop) {
			setCrop(newCrop);
		}
	};
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCompletedCrop(croppedAreaPixels);
	};

	const generateCroppedImage = async () => {
		try {
			const croppedImageBlob = await getCroppedImg(src, completedCrop);

			const croppedImageFile = new File(
				[croppedImageBlob],
				'cropped-image.jpg',
				{
					type: croppedImageBlob.type || 'image/jpeg',
				}
			);
			setNewBanners((prev) => [
				...prev,
				{
					preview: URL.createObjectURL(croppedImageFile),
					file: croppedImageFile,
				},
			]);

			setBlankBanner([]);

			setSrc(null);
		} catch (error) {
			console.error('Error generating cropped image:', error);
		}
	};

	const handleCheckboxChange = (e) => {
		if (e.target.checked !== isChecked) {
			if (e.target.checked) {
				setIsChecked(true);
				setConfirmVisible(false);
			} else {
				setConfirmVisible(true);
			}
		}
	};

	const accept = () => {
		setIsChecked(false);
		setConfirmVisible(false);
	};

	const reject = () => {
		setConfirmVisible(false);
	};

	const handleUpdateData = useCallback(
		async (e) => {
			e.preventDefault();
			setLoading(true);
			const formData = new FormData(e.target);
			formData.append('category_id', data?.product_category_id);
			if (categoryIconInputRef.current.files.length > 0) {
				const categoryIcon = categoryIconInputRef.current.files[0];
				formData.append('icon', categoryIcon);
			}
			if (newBanners?.length > 0) {
				newBanners.forEach((banner) => {
					if (banner.file && banner.file instanceof File) {
						formData.append('banners', banner.file);
					}
				});
			}

			formData.set('enabled', isChecked ? 'Y' : 'N');
			formData.append(
				'parent_category_id',
				selectedParentCategory?.value || ''
			);

			try {
				const response = await postAxios(
					request.updatePoductCategory,
					formData
				);
				if (response?.data?.apiresponse?.type === 'OK') {
					changeToast(
						Constants.TOAST_SUCCESS,
						Constants.TOAST_SUCCESS_TITLE,
						response?.data?.apiresponse?.message
					);
					setNewBanners([]);
					fetchData();
					setBlankBanner([]);
				} else {
					changeToast(
						Constants.TOAST_ERROR,
						Constants.TOAST_ERROR_TITLE,
						response?.data?.apiresponse?.message
					);
				}

				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		},
		[data, isChecked, selectedParentCategory, newBanners]
	);

	useEffect(() => {
		if (!data) return;
		const categoryIcon = data?.category_contents?.find(
			(content) => content?.content_type_id === 'CATEGORY_ICON'
		);
		const categoryBanner = data?.category_contents?.filter(
			(content) => content?.content_type_id === 'CATEGORY_BANNER'
		);
		if (data?.enabled === 'Y') {
			setIsChecked(true);
		}
		if (data?.primary_parent_category_id) {
			setSelectedParentCategory(options);
		}
		setCategoryIconPreview(categoryIcon || '/images/no-icon.webp');

		if (categoryBanner && categoryBanner?.length > 0) {
			setExistingBanners(categoryBanner);
		} else if (categoryBanner?.length === 0) {
			setBlankBanner(['/images/no-image.webp']);
		}
	}, [data]);

	const loadOptions = async (inputValue, callback) => {
		if (inputValue.length < 3) {
			callback([]);
			return;
		}
		try {
			const response = await getAxios(`${request.globalCategoryList}`, {
				params: { term: inputValue },
			});
			const options = response.data.record.map((item) => ({
				label: `${item?.category_name}(${item?.product_category_id})`,
				value: item?.product_category_id,
			}));
			callback(options);
		} catch (error) {
			console.error('Error fetching data:', error);
			callback([]);
		}
	};

	const handleDeleteBanner = useCallback(
		async (index = null, type, content_id) => {
			try {
				if (type === 'new') {
					setNewBanners((prev) => prev.filter((_, i) => i !== index));
					if (newBanners?.length === 0 && existingBanners?.length === 0) {
						setBlankBanner(['/images/no-image.webp']);

						bannerInputRef.current.value = '';
					}
				}
				if (type === 'newIcon') {
					setCategoryIconPreview(null);
					if (categoryIconInputRef.current) {
						categoryIconInputRef.current.value = '';
					}
					setCategoryIconPreview('/images/no-icon.webp');
				}

				if (!content_id) {
					return;
				}
				const response = await deleteAxios(request.deleteContentCategory, {
					params: {
						content_id: content_id,
						category_id: data?.product_category_id,
					},
				});
				if (response?.data?.apiresponse?.type === 'OK') {
					if (type === 'banner' && index !== null) {
						setExistingBanners((prev) => prev.filter((_, i) => i !== index));
						if (bannerInputRef.current) {
							bannerInputRef.current.value = '';
						}
					} else if (type === 'icon') {
						setCategoryIconPreview(null);
						if (categoryIconInputRef.current) {
							categoryIconInputRef.current.value = '';
						}
					}
					changeToast(
						Constants.TOAST_SUCCESS,
						Constants.TOAST_SUCCESS_TITLE,
						response?.data?.apiresponse?.message
					);
					fetchData();
				}
			} catch (error) {
				console.log(error);
			}
		},
		[data]
	);
	return (
		<>
			<div className="w-100 d-flex flex-row gap-5">
				<div className="update-category-details-container  d-flex flex-column gap-0 p-4">
					<>
						{loading && <Loader />}
						{globalCategoryDetails.map((detail, index) => (
							<div
								key={index}
								className="update-category-detail d-flex flex-column"
							>
								<span>{detail.label}</span>
								{detail.label === 'DESCRIPTION' ? (
									<span className="fw-normal">{detail.name}</span>
								) : (
									<span className="">{detail.name}</span>
								)}
								{detail.ID && (
									<span className="">
										<strong>ID:</strong> {detail.ID}
									</span>
								)}
							</div>
						))}

						<span className="icon-class">CATEGORY ICON : </span>
						<div className="  category-preview ">
							{categoryIconPreview && (
								<div className="d-flex flex-column">
									<div className="m-2 position-relative category-preview-container">
										<img
											src={
												categoryIconPreview?.object_info?.small?.public_url ||
												categoryIconPreview ||
												'/images/no-icon.webp'
											}
											alt="Category Icon Preview"
											style={{ width: '100px', height: '100px' }}
											className="p-1 rounded"
										/>

										{categoryIconPreview != '/images/no-icon.webp' && (
											<Button
												type="button"
												className="position-absolute category-preview-iconBtn"
												onClick={() => {
													console.log('Clicked');
													const isBlobUrl =
														typeof categoryIconPreview === 'string' &&
														categoryIconPreview.startsWith('blob:');
													if (isBlobUrl) {
														handleDeleteBanner(
															null,
															'newIcon',
															categoryIconPreview?.content_id
														);
													} else {
														handleDeleteBanner(
															null,
															'icon',
															categoryIconPreview?.content_id
														);
													}
												}}
												icon="pi pi-times"
												aria-label="Cancel"
												rounded
												severity="danger"
												text
												raised
											/>
										)}
									</div>
									{/* <p className='icon-class'>{categoryIconPreview?.content_id}</p> */}
								</div>
							)}
						</div>

						<span className="icon-class">CATEGORY BANNERS : </span>
						<div className="mb-2  gap-2 banner-category ">
							{existingBanners?.map((fileURL, index) => (
								<div className="">
									<div
										key={index}
										className="position-relative banner-category-container "
									>
										<img
											src={fileURL?.object_info?.small?.public_url}
											alt={`Banner Preview ${index}`}
											style={{ width: '100%' }}
											className=" p-1 rounded"
										/>

										<Button
											className="position-absolute category-preview-bannerBtn"
											type="button"
											icon="pi pi-times"
											rounded
											text
											severity="danger"
											onClick={() =>
												handleDeleteBanner(index, 'banner', fileURL?.content_id)
											}
											aria-label="Cancel"
										/>
									</div>
									{/* <p className='icon-class'>{fileURL?.content_id}</p> */}
								</div>
							))}
							{newBanners?.length > 0
								? newBanners.map((fileURL, index) => (
										<div
											key={index}
											className="position-relative banner-category-container "
										>
											<img
												src={fileURL?.preview}
												alt={`Banner Preview ${index}`}
												style={{ width: '100%' }}
												className="p-1 rounded"
											/>
											{fileURL?.preview && (
												<Button
													className="position-absolute category-preview-bannerBtn "
													type="button"
													icon="pi pi-times"
													rounded
													text
													severity="danger"
													onClick={() => handleDeleteBanner(index, 'new')}
													aria-label="Cancel"
												/>
											)}
										</div>
									))
								: blankBanner.map((image, index) => (
										<div
											key={index}
											className="position-relative banner-category-container "
										>
											<img
												src={image}
												alt={`Blank Banner ${index}`}
												style={{ width: '100%' }}
												className="p-1 rounded"
											/>
										</div>
									))}
						</div>
					</>
				</div>

				<div className=" card update-category-form d-flex align-items-center align-self-start">
					<ConfirmDialog
						visible={confirmVisible}
						onHide={() => setConfirmVisible(false)}
						message="Are you sure you want to change the status Doing this will unlist your category?"
						header="Confirmation"
						icon="pi pi-exclamation-triangle"
						accept={accept}
						reject={reject}
					/>

					<div className=" w-100 d-flex justify-content-center p-4 m-auto">
						<div className="card-body">
							<form onSubmit={handleUpdateData}>
								<div className=" mb-3">
									<label htmlFor="" className="form-label">
										Name
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Name"
										name="category_name"
										defaultValue={data?.category_name || ''}
									/>
								</div>
								<div className=" mb-3">
									<label htmlFor="" className="form-label">
										Description
									</label>
									<textarea
										type="text"
										className="form-control"
										name="description"
										placeholder="Description"
										defaultValue={data?.description || ''}
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="" className="form-label">
										Parent Category ID
										<span
											style={{
												color: 'red',
												fontSize: '12px',
												fontWeight: 'bold',
											}}
										>
											{' '}
											(Use with caution)
										</span>
									</label>

									<AsyncSelect
										cacheOptions
										loadOptions={loadOptions}
										defaultOptions
										onChange={setSelectedParentCategory}
										value={selectedParentCategory}
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="formFile">Upload Category Icon</label>
									<span
										style={{
											color: '#999',
											fontSize: '12px',
										}}
									>
										{' '}
										Only Svg files supported with icon size of 512px/512px.
									</span>
									<br></br>
									{categoryIconPreview?.object_info?.small?.public_url && (
										<span
											style={{
												color: 'red',
												marginBottom: '5px',
											}}
											className="icon-delete"
										>
											Kindly delete previous category icon before uploading new
											one
										</span>
									)}
									<input
										className={`form-control ${categoryIconPreview?.object_info?.small?.public_url ? 'categoryIcon-disabled' : ''}`}
										type="file"
										id="formFile"
										ref={categoryIconInputRef}
										onChange={handleCategoryIconChange}
										disabled={
											categoryIconPreview?.object_info?.small?.public_url
										}
									/>
								</div>

								<div className="mb-3">
									<label htmlFor="formFile" className="form-label">
										Upload Banners
									</label>
									<span
										style={{
											color: '#999',
											fontSize: '12px',
										}}
									>
										{' '}
										Only JPEG, PNG, and WebP files supported with banner size of
										1536px/315px
									</span>
									<input
										className="form-control"
										type="file"
										id="formFile"
										multiple
										ref={bannerInputRef}
										onChange={handleBannerChange}
										accept="image/*"
									/>
								</div>
								<div className="form-check mb-4">
									<input
										className="form-check-input"
										type="checkbox"
										name="enabled"
										// value="Y"
										id="flexCheckDefault"
										defaultChecked={data?.enabled === 'Y'}
										checked={isChecked}
										onChange={handleCheckboxChange}
									/>
									<label
										className="form-check-label"
										htmlFor="flexCheckDefault"
									>
										Enable
									</label>
								</div>

								<div className="d-flex prime-react-btn w-100 align-items-center gap-2 ">
									<button
										className="btn w-100 text-white"
										style={{ backgroundColor: 'grey' }}
										onClick={() => {
											navigate('/global-category-list');
										}}
									>
										Go Back
									</button>
									<button
										type="submit"
										className="btn submit-btn w-100 text-white"
									>
										{loading ? 'Updating...' : 'Update'}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<>
				<Dialog
					visible={!!src}
					onHide={() => {
						if (!src) return;
						bannerInputRef.current.value = '';
						setSrc(null);
					}}
					style={{ width: '90%', height: '80vh', overflow: 'auto' }}
				>
					<div className="cropped-container d-flex flex-column ">
						<div className="cropped-container-image">
							<Cropper
								image={src}
								crop={crop}
								zoom={zoom}
								minZoom={1}
								objectFit="contain"
								aspect={1536 / 315}
								onCropChange={onCropChange}
								onCropComplete={onCropComplete}
								onZoomChange={setZoom}
								style={{
									containerStyle: { height: '100%' },
									mediaStyle: { width: '40%' },
									
								}}
							/>
						</div>

						<div className="cropped-container-btn">
							<Button onClick={generateCroppedImage}>Crop and Save</Button>
						</div>
					</div>
				</Dialog>
			</>
		</>
	);
};
