import React, { useState, useEffect, useRef, useContext } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { postAxios } from "../../axios/Axios";
import request from "../../request/request";
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import ImageGallery from 'react-image-gallery';
import Loader from "../loader/Loader";
import { LoadingContext } from "../../context/Provider";
export default function AppPosting() {
    let emptyProduct = {
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK"
    };

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [contentType, setContentType] = useState("")
    const [mediaObject, setMediaObject] = useState("")
    const [title, setTitle] = useState()
    const [message, setMessage] = useState("")
    const [cta, setCta] = useState("")
    const [text1, setText1] = useState("")
    const [text2, setText2] = useState("")
    const [text3, setText3] = useState("")
    const [postingSelectType, setPostingSelectType] = useState("")
    const [postingSelectStatus, setPostingSelectStatus] = useState("")
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState("")
    const [currentImageShow, setCurrentImageShow] = useState(false)
    const [currentImageData, setCurrentImageData] = useState([])
    const loadingLoader=useContext(LoadingContext)
    console.log("first", currentImageData, currentImageShow)
    const postingType = [
        { name: 'PICTURE', code: 'PICTURE' },
        { name: 'SOCIAL', code: 'SOCIAL' },
        { name: 'VIDEO', code: 'VIDEO' },
        { name: 'BUY', code: 'BUY' },
        { name: 'SELL', code: 'SELL' },
        { name: 'JOB', code: 'JOB' },
        { name: 'HOMEPAGE_BANNER', code: 'HOMEPAGE_BANNER' }

    ]
    useEffect(() => {
        fetchSearchPost()
    }, [postingSelectStatus, postingSelectType])
    const postingStatus = [
        {
            name: "APPROVED", code: "APPROVED"
        }, {
            name: "PENDING", code: "PENDING"
        }, {
            name: "REJECTED", code: "REJECTED"
        }, {
            name: "DEVELOPMENT", code: "DEVELOPMENT"
        }, {
            name: "ALL", code: "ALL"
        },
    ]
    useEffect(() => {
        setContentType(product?.mediaobjects?.[0]?.mime_type)
        setTitle(product?.title)
        setMessage(product?.message)
        setCta(product?.cta)
        setText1(product?.text1)
        setText2(product?.text2)
        setText3(product?.text3)
    }, [productDialog])

    useEffect(() => {
        fetchData()
        // ProductService.getProducts().then((data) => setProducts(data));
    }, []);
    const fetchData = async () => {
        const data = {
            meta: { jtStartIndex: first, jtPageSize: rows },
            rows: rows,
            offset: first,
            show_all: 1
        }
        const res = await postAxios(request.getPost, data)
        setProducts(res.data.records)
        setTotalRecords(res.data.metadata.total)
        loadingLoader.loadingDisabled()
    }
    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };
    const resetSearch = () => {
        setPostingSelectStatus("")
        setPostingSelectType("")
    }
    const fetchSearchPost = async () => {
        const data = {
            posting_status: postingSelectStatus.code,
            posting_type: postingSelectType.code,
            meta: { jtStartIndex: first * rows, jtPageSize: rows },
            rows: rows,
            offset: first * rows,
            show_all: 1
        }
        const res = await postAxios(request.getPost, data)
        setProducts(res.data.records)
        setTotalRecords(res.data.metadata.total)
        loadingLoader.loadingDisabled()
    }
    const searchPost = () => {
        fetchSearchPost()
    }
    useEffect(() => {
        fetchData()
        loadingLoader.loadingEnabled()
    }, [rows, first])

    // const openNew = () => {
    //     setProduct(emptyProduct);
    //     setSubmitted(false);
    //     setProductDialog(true);
    // };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };
    const hideImageGalaryDialog = () => {
        setCurrentImageShow(false)
    }
    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = async () => {
        setSubmitted(true);
        const data = {
            cta: cta,
            id: product.id,
            message: message,
            posting_status: postingSelectStatus.code,
            posting_type: postingSelectType.code,
            text1: text1,
            text2: text2,
            text3: text3,
            title: title
        }
        const res = await postAxios(request.updatePost, data)
        console.log("types", res.data.apiresponse.type == "OK")
        if (res?.data?.apiresponse?.type == "OK") {
            fetchData()
            toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Product Updated Successfully",
                life: 3000
            });
            setProductDialog(false)
        } else {
            console.log("ok34...")
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Product Not Updated",
                life: 3000
            });
        }
    };

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    };

    const confirmDeleteProduct = async (product) => {
        setProduct(product);
        setDeleteProductDialog(true);

    };

    const deleteProduct = async () => {

        const data = {
            id: product.id
        }
        const res = await postAxios(request.deletePost, data)
        if (res.data.apiresponse.type == "OK") {
            let _products = products.filter((val) => val.id !== product.id);
            setProducts(_products);
            setDeleteProductDialog(false);
            setProduct(emptyProduct);
            toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Product Deleted",
                life: 3000
            });
        } else {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Product Not Deleted",
                life: 3000
            });
        }

    };

    // const findIndexById = (id) => {
    //     let index = -1;
    //     for (let i = 0; i < products.length; i++) {
    //         if (products[i].id === id) {
    //             index = i;
    //             break;
    //         }
    //     }

    //     return index;
    // };

    // const createId = () => {
    //     let id = "";
    //     let chars =
    //         "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    //     for (let i = 0; i < 5; i++) {
    //         id += chars.charAt(Math.floor(Math.random() * chars.length));
    //     }
    //     return id;
    // };

    // const importCSV = (e) => {
    //     const file = e.files[0];
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //         const csv = e.target.result;
    //         const data = csv.split("\n");

    //         // Prepare DataTable
    //         const cols = data[0].replace(/['"]+/g, "").split(",");
    //         data.shift();

    //         const importedData = data.map((d) => {
    //             d = d.split(",");
    //             const processedData = cols.reduce((obj, c, i) => {
    //                 c =
    //                     c === "Status"
    //                         ? "inventoryStatus"
    //                         : c === "Reviews"
    //                             ? "rating"
    //                             : c.toLowerCase();
    //                 obj[c] = d[i].replace(/['"]+/g, "");
    //                 (c === "price" || c === "rating") && (obj[c] = parseFloat(obj[c]));
    //                 return obj;
    //             }, {});

    //             processedData["id"] = createId();
    //             return processedData;
    //         });

    //         const _products = [...products, ...importedData];

    //         setProducts(_products);
    //     };

    //     reader.readAsText(file, "UTF-8");
    // };

    // const exportCSV = () => {
    //     dt.current.exportCSV();
    // };

    // const confirmDeleteSelected = () => {
    //     setDeleteProductsDialog(true);
    // };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Products Deleted",
            life: 3000
        });
    };

    // const onCategoryChange = (e) => {
    //     let _product = { ...product };
    //     _product["category"] = e.value;
    //     setProduct(_product);
    // };

    // const onInputChange = (e, name) => {
    //     const val = (e.target && e.target.value) || "";
    //     let _product = { ...product };
    //     _product[`${name}`] = val;

    //     setProduct(_product);
    // };

    // const onInputNumberChange = (e, name) => {
    //     const val = e.value || 0;
    //     let _product = { ...product };
    //     _product[`${name}`] = val;

    //     setProduct(_product);
    // };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="d-flex flex-wrap">

                    <div className="field mx-3">
                        <label htmlFor="description">Posting Type</label>
                        <Dropdown value={postingSelectType} onChange={(e) => setPostingSelectType(e.value)} options={postingType} optionLabel="name"
                            placeholder="Select a Status" className="w-full f-14 md:w-100" />
                    </div>

                    <div className="field mx-3">
                        <label htmlFor="description">Posting Status</label>
                        <Dropdown value={postingSelectStatus} onChange={(e) => setPostingSelectStatus(e.value)} options={postingStatus} optionLabel="name"
                            placeholder="Select a Status" className="w-full f-14 md:w-100" />
                    </div>
                    <div className="" style={{ alignSelf: "center" }}>
                        <button className="btn btn-primary mx-2 p-2" onClick={resetSearch}>RESET</button>
                        <button className="btn btn-success mx-2 p-2" onClick={searchPost}>SEARCH</button>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const imageBodyTemplate = (rowData) => {

        const currentImage = () => {
            const imageGalleryArray = []
            for (var i = 0; i < rowData.mediaobjects?.length; i++) {
                var data = {
                    original: rowData.mediaobjects[i].url,
                    thumbnail: rowData.mediaobjects[i].thumb_url
                }
                imageGalleryArray.push(data)
            }
            setCurrentImageShow(true)
            setCurrentImageData(imageGalleryArray)
        }
        return (
            <div className="w-100 h-100 cursor-pointer" onClick={currentImage}>
                <div className="d-flex align-items-center justify-content-center">
                <img
                    src={rowData?.mediaobjects?.[0]?.url}
                    alt={rowData?.mediaobjects?.[0]?.fileName}
                    className="product-image" style={{width:"100px"}}
                />
                </div>
             
            </div>
        );
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-success mr-2"
                    onClick={() => editProduct(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-warning"
                    onClick={() => confirmDeleteProduct(rowData)}
                />
            </React.Fragment>
        );
    };

    const header = (
        <div>

            <div className="table-header">
                <h5 className="mx-0 my-1 f-18">Manage Products</h5>

                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search..."
                    />
                </span>
            </div>
           <div>
                    Total Records:<span className="f-300">{totalRecords}</span>
                </div>
        </div>
    );
    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDialog}
            />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={saveProduct}
            />
        </React.Fragment>
    );
    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteProductDialog}
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteProduct}

            />
        </React.Fragment>
    );
    const deleteProductsDialogFooter = (
        <React.Fragment>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteProductsDialog}
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteSelectedProducts}
            />
        </React.Fragment>
    );

    const mediaObjectTemplate = (rowData) => {
        return (
            <div>
                <div>
                    <span className="font-bold ">No Of Media:</span><span className=""> {rowData?.mediaobjects?.length}</span>
                </div>
                <div>
                    <span className="font-bold ">FileName:</span><span> {rowData?.mediaobjects?.[0]?.filename}</span>
                </div>
                <div>
                    <span className="font-bold">Mime Type:</span><span> {rowData?.mediaobjects?.[0]?.mime_type}</span>
                </div>
            </div>
        )
    }
    const messageTemplate = (rowData) => {
        return (
            <div>
                <div>
                    <span className="font-bold">Title:</span><span> {rowData.title}</span>
                </div>
                <div>
                    {rowData.message}
                </div>
            </div>
        )
    }
    const textTemplate = (rowData) => {
        return (
            <div>
                <div>
                    <span className="font-bold">Text1:</span><span>{rowData.text1 ? rowData.text1 : "N/A"}</span>
                </div>
                <div>
                    <span className="font-bold">Text2:</span><span>{rowData.text2 ? rowData.text2 : "N/A"}</span>
                </div>
                <div>
                    <span className="font-bold">Text3:</span><span>{rowData.text3 ? rowData.text3 : "N/A"}</span>
                </div>
            </div>
        )
    }
    return (
        <div className="datatable-crud-demo">
            <Toast ref={toast} />
            {loadingLoader.loading&&
            <Loader/>}

            <div className="image-gallery">
                {currentImageShow &&
                    <Dialog
                        visible={currentImageShow}
                        style={{ width: "80%", height: "85%", maxHeight: "100%" }}
                        header="Product Details"
                        modal
                        className="p-fluid"
                        onHide={hideImageGalaryDialog}
                    >
                        <ImageGallery originalHeight="100vh" originalWidth="100vw" fullscreen={true} items={currentImageData} />
                    </Dialog>
                }
            </div>
            <div className="card">
                <Toolbar
                    className="mb-4"
                    left={leftToolbarTemplate}
                ></Toolbar>

                <DataTable
                    ref={dt}
                    value={products}
                    selection={selectedProducts}
                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                    dataKey="id"
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    globalFilter={globalFilter}
                    header={header}
                    responsiveLayout="scroll"
                    showGridlines
                >
                    {/* <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "3rem" }}
                        exportable={false}
                    ></Column> */}
                    <Column
                        field="id"
                        header="Id"
                        sortable
                        style={{ minWidth: "3rem" }}
                    ></Column>
                    <Column
                        field=""
                        header="images/video"
                        body={imageBodyTemplate}
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="media"
                        header="MediaObjects"
                        sortable
                        body={mediaObjectTemplate}
                        style={{ minWidth: "12rem" }}
                    ></Column>
                    <Column
                        field="message"
                        header="Message"
                        sortable
                        body={messageTemplate}
                        style={{ minWidth: "16rem" }}
                    ></Column>
                    <Column
                        field="posting_type"
                        header="Posting Type"
                        sortable
                        style={{ minWidth: "5rem" }}
                    ></Column>
                    <Column
                        field="posting_by"
                        header="Posting By"
                        sortable
                        style={{ minWidth: "6rem" }}
                    ></Column>
                    <Column
                        field="posting_status"
                        header="Posting Status"
                        sortable
                        style={{ minWidth: "6rem" }}
                    ></Column>
                    <Column
                        field="by_user_login"
                        header="By User Login"
                        sortable
                        style={{ minWidth: "6rem" }}
                    ></Column>
                    <Column
                        field="cta"
                        header="CTA"
                        sortable
                        style={{ minWidth: "6rem" }}
                    ></Column>
                    <Column
                        field="Text"
                        header="Text"
                        sortable
                        body={textTemplate}
                        style={{ minWidth: "6rem" }}
                    ></Column>
                    <Column
                        body={actionBodyTemplate}
                        exportable={false}
                        style={{ minWidth: "10rem" }}
                    ></Column>
                </DataTable>

                <div className="card">
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={totalRecords}
                        rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        onPageChange={onPageChange} />
                </div>
            </div>

            <Dialog
                visible={productDialog}
                style={{ width: "80%" }}
                header="Product Details"
                modal
                className="p-fluid"
                footer={productDialogFooter}
                onHide={hideDialog}
            >
                <div className="row">
                    <div className="col-6">
                        <div className="field">
                            <label htmlFor="image">Images/Videos</label>
                            <InputText
                                id="image"
                                value={contentType}
                                onChange={(e) => setContentType(e.target.value)}
                                required
                                autoFocus
                                className={classNames({ "p-invalid": submitted && !product.name })}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="mediaobject">MediaObjects</label>
                            <InputText
                                id="mediaobject"
                                value={mediaObject}
                                onChange={(e) => setMediaObject(e.target.value)}
                                required
                                autoFocus
                                className={classNames({ "p-invalid": submitted && !product.name })}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="title">Title</label>
                            <InputText
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                                autoFocus
                                className={classNames({ "p-invalid": submitted && !product.name })}
                            />
                        </div>

                        <div className="field">
                            <label htmlFor="postingType">Posting Type</label>
                            <Dropdown value={postingSelectType} onChange={(e) => setPostingSelectType(e.value)} options={postingType} optionLabel="name"
                                placeholder="Select a Status" className="w-full md:w-100" />
                        </div>
                        <div className="field">
                            <label htmlFor="message">Message</label>
                            <InputTextarea
                                id="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                rows={3}
                                cols={20}
                            />
                        </div>
                    </div>
                    <div className="col-6">

                        <div className="field">
                            <label htmlFor="description">Posting Status</label>
                            <Dropdown value={postingSelectStatus} onChange={(e) => setPostingSelectStatus(e.value)} options={postingStatus} optionLabel="name"
                                placeholder="Select a Status" className="w-full md:w-100 text-uppercase" />
                        </div>
                        <div className="field">
                            <label htmlFor="cta">CTA</label>
                            <InputText
                                id="cta"
                                value={cta}
                                onChange={(e) => setCta(e.target.value)}
                                required
                                autoFocus
                                className={classNames({ "p-invalid": submitted && !product.name })}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="text1">Text1</label>
                            <InputText
                                id="text1"
                                value={text1}
                                onChange={(e) => setText1(e.target.value)}
                                required
                                autoFocus
                                className={classNames({ "p-invalid": submitted && !product.name })}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="text2">Text2</label>
                            <InputText
                                id="text2"
                                value={text2}
                                onChange={(e) => setText2(e.target.value)}
                                required
                                autoFocus
                                className={classNames({ "p-invalid": submitted && !product.name })}
                            />
                        </div>

                        <div className="field">
                            <label htmlFor="text3">Text3</label>
                            <InputText
                                id="text3"
                                value={text3}
                                onChange={(e) => setText3(e.target.value)}
                                required
                                autoFocus
                                className={classNames({ "p-invalid": submitted && !product.name })}
                            />
                        </div>
                    </div>
                </div>

            </Dialog>

            <Dialog
                visible={deleteProductDialog}
                style={{ width: "450px" }}
                header="Confirm"
                modal
                footer={deleteProductDialogFooter}
                onHide={hideDeleteProductDialog}
            >
                <div className="confirmation-content">
                    <i
                        className="pi pi-exclamation-triangle mr-3"
                        style={{ fontSize: "2rem" }}
                    />
                    {product && (
                        <span>
                            Are you sure you want to delete <b>{product.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog
                visible={deleteProductsDialog}
                style={{ width: "450px" }}
                header="Confirm"
                modal
                footer={deleteProductsDialogFooter}
                onHide={hideDeleteProductsDialog}
            >
                <div className="confirmation-content">
                    <i
                        className="pi pi-exclamation-triangle mr-3"
                        style={{ fontSize: "2rem" }}
                    />
                    {product && (
                        <span>Are you sure you want to delete the selected products?</span>
                    )}
                </div>
            </Dialog>
        </div>
    );
}
