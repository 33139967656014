import React from 'react';
import { useEffect, useRef, useState, memo } from 'react';
import { Modal, Button } from 'react-bootstrap'
import { Carousel } from 'primereact/carousel';
function CaptionModal(props) {
    const [products, setProducts] = useState([]);
    const captionImage = useRef(null)

    useEffect(() => {
        setProducts(props.images)
    }, [props.images])

    const doneBtn = () => {
        let title = "";
        const titlesArray = props.images.map((value, index) => {
            return document.getElementById(`captionInput${index}`).value;
        });
        
        title = titlesArray.join(",");
        props.setTitleList(title);
        props.onHide();
    }

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const productTemplate = (image) => {
        return (
            <div >
                <input placeholder='Add Caption' className='caption_input' type="text" id={`captionInput${image.id}`} />
                <img className='w-100' src={image.data_url} alt=''/>
            </div>
        )
    }

    return (
        <Modal
            {...props}
            className="modal-caption"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div ref={captionImage}>

                    <Carousel value={products} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions}
                        itemTemplate={productTemplate} />
                </div>
                {/* <div>
            <img className='h-auto w-100' src={props?.images[0]?.data_url}/>
            </div> */}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
                <button className='btn btn-success' onClick={doneBtn}>Done</button>
            </Modal.Footer>
        </Modal>
    );
}
export default memo(CaptionModal)