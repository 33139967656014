import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { postAxios } from '../../axios/Axios'
import request from '../../request/request'
import ImageUploading from "react-images-uploading";
import CaptionModal from '../modal/CaptionModal'

function CreatePost() {
    const [partyId, setPartyId] = useState("")
    const [title, setTitle] = useState("")
    const [text1, setText1] = useState("")
    const [text2, setText2] = useState("")
    const [text3, setText3] = useState("")
    const [message, setMessage] = useState("")
    const [cta, setCta] = useState("")
    const [postingSelectType, setPostingSelectType] = useState("")
    const [postingSelectStatus, setPostingSelectStatus] = useState("")
    const [files
        // ,setFiles
    ] = useState()
    const [modalShow, setModalShow] = useState(false)
    const [images, setImages] = React.useState([]);
    const [titleList,setTitleList]=useState("")
    const [latitude,setLatitude]=useState("")
    const [longitude,setLongitude]=useState("")
    const maxNumber = 69;

    const onChange = (imageList, addUpdateIndex) => {
        const updatedImageList = imageList.map((value, index) => {
            return {
                ...value, 
                id: index
            };
        });
    
        setImages(updatedImageList);
        setModalShow(true);
    
        console.log(updatedImageList, addUpdateIndex);
    };
    

    console.log("file", files)
    const postingType = [
        { name: 'PICTURE', code: 'PICTURE' },
        { name: 'SOCIAL', code: 'SOCIAL' },
        { name: 'VIDEO', code: 'VIDEO' },
        { name: 'BUY', code: 'BUY' },
        { name: 'SELL', code: 'SELL' },
        { name: 'JOB', code: 'JOB' },
        { name: 'HOMEPAGE_BANNER', code: 'HOMEPAGE_BANNER' }

    ]
    const postingStatus = [
        {
            name: "APPROVED", code: "APPROVED"
        }, {
            name: "PENDING", code: "PENDING"
        }, {
            name: "REJECTED", code: "REJECTED"
        }, {
            name: "DEVELOPMENT", code: "DEVELOPMENT"
        }, {
            name: "ALL", code: "ALL"
        },
    ]
    // const handleChangeFile = (e) => {
    //     setFiles(e.target.files);
    // }
    const resetFunction = () => {
        setPartyId("")
        setTitle("")
        setText1("")
        setText2("")
        setText3("")
        setMessage("")
        setCta("")
        setPostingSelectType("")
        setPostingSelectStatus("")
        setImages([])
        setTitleList("")
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        console.log("event", event.target.files)
        const data = {
            cta: cta,
            message: message,
            party_id: partyId,
            posting_status: postingSelectStatus.code,
            text1: text1,
            text2: text2,
            text3: text3,
            title: title,
            type: postingSelectType.code
        }
        const res = await postAxios(request.createPost, data)
        if (res.data.apiresponse.type === "OK") {
            const formData = new FormData()
            formData.append("post_id", res.data.record.id)
            for (var i = 0; i < images.length; i++) {
                formData.append("medialist", images[i].file)
            }
            formData.append("titlelist",titleList)
            formData.append("longitude",longitude)
            formData.append("latitude",latitude)
            await postAxios(request.uploadPostAttachments, formData)
        }
    }
console.log("title",titleList)
    return (
        <div className='create-post container'>
            <div className='card'>
                <CaptionModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    images={images}
                    handleSubmit={handleSubmit}
                    setTitleList={setTitleList}
                />
                <div className='card-body'>
                    <p className='text-center fs-2'>Add Post</p>
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="field">
                                    <label htmlFor="partyId">Party Id</label>
                                    <InputText
                                        id="partyId"
                                        value={partyId}
                                        onChange={(e) => setPartyId(e.target.value)}
                                        required
                                        autoFocus
                                        placeholder='Please Enter The Party Id'
                                    />
                                </div>
                            </div>
                            <div className='col-6'></div>
                            <div className='col-6'>
                                <div className="field">
                                    <label htmlFor="description">Posting Status</label>
                                    <Dropdown optionLabel="name" value={postingSelectStatus} onChange={(e) => setPostingSelectStatus(e.value)}
                                        placeholder="Select a Status" options={postingStatus} className="w-full md:w-100 text-uppercase" />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="field">
                                    <label htmlFor="description">Posting Type</label>
                                    <Dropdown optionLabel="name" value={postingSelectType} onChange={(e) => setPostingSelectType(e.value)} options={postingType}
                                        placeholder="Select Posting Type" className="w-full md:w-100 text-uppercase" />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="field">
                                    <label htmlFor="title">Title</label>
                                    <InputText
                                        id="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                        placeholder='Please Enter The Title'
                                        autoFocus
                                    />
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="field">
                                    <label htmlFor="message">Message</label>
                                    <InputTextarea
                                        id="message"
                                        required
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        rows={3}
                                        cols={20}
                                        placeholder="Please Enter The Message Here..."
                                    />
                                </div>
                            </div>
                            
                           
                        
                            <div className='col-6'>
                                <div className="field">
                                    <label htmlFor="text1">Text1</label>
                                    <InputText
                                        id="text1"
                                        required
                                        autoFocus
                                        placeholder='Please Enter The text1'
                                        value={text1}
                                        onChange={(e) => setText1(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="field">
                                    <label htmlFor="text2">Text2</label>
                                    <InputText
                                        id="text2"
                                        required
                                        autoFocus
                                        value={text2}
                                        onChange={(e) => setText2(e.target.value)}
                                        placeholder="Please Enter The text2"
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="field">
                                    <label htmlFor="text3">Text3</label>
                                    <InputText
                                        id="text3"
                                        required
                                        autoFocus
                                        value={text3}
                                        onChange={(e) => setText3(e.target.value)}
                                        placeholder="Please Enter The text3"
                                    />
                                </div>
                            </div>
                         
                            <div className='col-12'>
                                <div className="field">
                                    <label htmlFor="message">CTA</label>
                                    <InputTextarea
                                        id="message"
                                        required
                                        rows={3}
                                        cols={20}
                                        value={cta}
                                        onChange={(e) => setCta(e.target.value)}
                                        placeholder="Please Enter The CTA Here..."
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="field">
                                    <label htmlFor="latitude">Latitude</label>
                                    <InputText
                                        id="latitude"
                                        required
                                        autoFocus
                                        value={latitude}
                                        onChange={(e) => setLatitude(e.target.value)}
                                        placeholder="Please Enter The Latitude"
                                    />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="field">
                                    <label htmlFor="longitude">Longitude</label>
                                    <InputText
                                        id="longitude"
                                        required
                                        autoFocus
                                        value={longitude}
                                        onChange={(e) => setLongitude(e.target.value)}
                                        placeholder="Please Enter The Longitude"
                                    />
                                </div>
                            </div>
                        
                            
                            <div className='col-6 d-flex align-self-center'>
                                Click on "Browse" button to upload 1st file
                                <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={onChange}
                                    maxNumber={maxNumber}
                                    dataURLKey="data_url"
                                // acceptType={["jpg"]}
                                >
                                    {({
                                        onImageUpload,
                                        isDragging,
                                        dragProps
                                    }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <button
                                                style={isDragging ? { color: "red" } : null}
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                choose files
                                            </button>
                                            <div>
                                                <div className='d-flex flex-wrap'>

                                                </div>

                                            </div>

                                        </div>
                                    )}
                                </ImageUploading>
                                {/* <input type="file" id="file" multiple onChange={handleChangeFile} /> */}
                            </div>
                            <div className='col-12'>
                                <div className='d-flex flex-wrap'>
                                    {images.map((image, index) => (
                                        <div key={index} className="image-item">
                                            <img className='' style={{ width: "50px", height: "50px" }} src={image.data_url} alt="" width="100" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='col-6 text-center'>
                                <div className='d-flex flex-wrap'>
                                    <button className='btn btn-primary'>Cancel</button>
                                    <button className='btn btn-primary mx-3' onClick={resetFunction}>Reset</button>
                                    <button className='btn btn-success mx-3' type='submit'>Submit</button>

                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreatePost